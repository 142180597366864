import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React, { useEffect, useRef } from 'react'

import PageHead from 'components/head/PageHead'
import PageWrapper from 'components/layout/page-wrapper'
import {
  AssetsSection,
  CalculatorSection,
  CarouselSection,
  HeaderSection,
} from 'components/pages/earn-crypto'
import data from 'data/pages/Contents/earn-crypto.json'
import useFetchApyAssets from 'js/assets/hooks/useFetchApyAssets'
import { useRellax, useScrollToAnchor } from 'js/utils/hooks'
import { useAssets } from 'js/utils/hooks/useAssetsBase'

import 'components/pages/earn-crypto/scss/DisclosureSection.scss'
import 'static/rewards/scss/styles.scss'

const filterAssetsWithApy = (arr, assetList) =>
  arr.length > 0 &&
  arr
    .map((asset) => assetList.includes(asset.ticker) && asset)
    .filter((el) => el !== false)
    .sort((a, b) => b.marketcap - a.marketcap)

function Rewards({ pageName = 'earn-crypto', pageData = data, location }) {
  // get page data.
  const page = pageName
  const { assetList, content } = pageData
  const { header, assetsWithAPY, disclosure, calculator, carousel } = content

  const backgroundRef = useRef(null)

  const assetsData = useAssets()

  const dataObjects = { assetsData }

  const { assets } = useFetchApyAssets(dataObjects)
  let filteredAssetsWithApy = filterAssetsWithApy(assets, assetList)

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      filteredAssetsWithApy = filterAssetsWithApy(assets, assetList)
    }, 1000 * 60)

    return clearInterval(fetchInterval)
  }, [assets, assetList])

  const hasRates =
    filteredAssetsWithApy.length > 0 &&
    filteredAssetsWithApy.every((obj) => Object.prototype.hasOwnProperty.call(obj, 'apy'))

  const hasPrices =
    filteredAssetsWithApy.length > 0 &&
    filteredAssetsWithApy.every(
      (obj) =>
        Object.prototype.hasOwnProperty.call(obj, 'price') &&
        Object.prototype.hasOwnProperty.call(obj.price, 'current')
    )

  useScrollToAnchor(hasRates && hasPrices)

  useRellax({ ref: backgroundRef, center: false })

  // get images.
  const images = useStaticQuery(graphql`
    query {
      background400: file(relativePath: { regex: "/^rewards\/img\/header-bg-ssize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 400, placeholder: BLURRED) }
      }
      background800: file(relativePath: { regex: "/^rewards\/img\/header-bg-msize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 800, placeholder: BLURRED) }
      }
      background1600: file(relativePath: { regex: "/^rewards\/img\/header-bg-lsize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      rewardsSlide1: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide2: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide3: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide4: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_4\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide5: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_5\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      restakingSlide1: file(relativePath: { regex: "/^rewards\/img\/restaking\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      restakingSlide2: file(relativePath: { regex: "/^rewards\/img\/restaking\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      restakingSlide3: file(relativePath: { regex: "/^rewards\/img\/restaking\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      ethstakingSlide1: file(relativePath: { regex: "/^rewards\/img\/ethstaking\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      ethstakingSlide2: file(relativePath: { regex: "/^rewards\/img\/ethstaking\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      ethstakingSlide3: file(relativePath: { regex: "/^rewards\/img\/ethstaking\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
    }
  `)

  const backgroundImage = withArtDirection(getImage(images.background1600), [
    { media: '(max-width: 800px)', image: getImage(images.background800) },
    { media: '(max-width: 400px)', image: getImage(images.background400) },
  ])

  // carousel images.
  const carouselImageMap = {
    rewardsCarousel: [
      getImage(images.rewardsSlide1),
      getImage(images.rewardsSlide2),
      getImage(images.rewardsSlide3),
      getImage(images.rewardsSlide4),
      getImage(images.rewardsSlide5),
    ],
    restakingCarousel: [
      getImage(images.restakingSlide1),
      getImage(images.restakingSlide2),
      getImage(images.restakingSlide3),
    ],
    ethstakingCarousel: [
      getImage(images.ethstakingSlide1),
      getImage(images.ethstakingSlide2),
      getImage(images.ethstakingSlide3),
    ],
  }

  const checkEthExists =
    assetsWithAPY.length > 0 && assetsWithAPY.some((asset) => asset.ticker === 'ETH')

  const carouselData = carousel
    .map((item) => {
      const key = Object.keys(item)[0]
      const imagesArray = carouselImageMap[key] || []
      const maybeExcludeEth = key === 'ethstakingCarousel' && !checkEthExists
      return !maybeExcludeEth ? { ...item[key], images: imagesArray } : null
    })
    .filter((el) => el !== null)

  return (
    <PageWrapper
      className="x__earn-crypto-page"
      backgroundColor="rgb(17, 19, 23)"
      headerProps={{ background: 'black', location, trackerPage: pageName }}
    >
      <div
        ref={backgroundRef}
        className="x__earn-crypto-page__background-container"
        data-rellax-speed="-4"
        role="presentation"
      >
        <div className="x__earn-crypto-page__background">
          <GatsbyImage image={backgroundImage} alt={header.alt} loading="eager" />
        </div>
      </div>
      {!!header && <HeaderSection data={header} page={page} location={location} />}
      {!!assetsWithAPY && <AssetsSection data={assetsWithAPY} assets={filteredAssetsWithApy} />}
      <span
        className="x__earn-crypto-page__disclosure"
        dangerouslySetInnerHTML={{ __html: disclosure }}
      />
      {!!calculator && <CalculatorSection data={calculator} assets={filteredAssetsWithApy} />}
      {!!carouselData?.length &&
        carouselData.map((carouselItem, i) => (
          <CarouselSection key={i + 1} data={carouselItem} variant={i + 1} />
        ))}
    </PageWrapper>
  )
}

export default Rewards

// <head> component:
export function Head() {
  return <PageHead page="earn-crypto" />
}
