// dependencies.
import React from 'react'
import { useIcon } from '../../../../js/utils/hooks/useIcons'

// Main component:
const AssetItemBackground = ({ asset }) => {
  const { name, ticker, apy } = asset
  const lowerCaseTicker = ticker.toLowerCase()

  const icon = useIcon(asset.assetName)
  let colors
  try {
    const AssetData = require(`src/data/pages/Assets/${lowerCaseTicker}.json`)
    colors = AssetData.colors
  } catch (err) {
    colors = asset.colors
  }

  const alt = `${name} (${ticker}) - APY ${apy}%`
  const colorStart = colors.gradient[1]
  const colorStop = colors.gradient[0]
  const id = `asset-${lowerCaseTicker}`

  return (
    <figure className="x__asset-item__background" id={id}>
      <img src={icon} alt={alt} />

      <svg
        width={170}
        height={287}
        viewBox="0 0 170 287"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{alt}</title>
        <path
          opacity={0.5}
          d="M10 87.5302C10 86.594 10.3719 85.6962 11.0339 85.0342C11.6959 84.3722 12.5938 84.0002 13.53 84.0002H156.47C157.406 84.0002 158.304 84.3722 158.966 85.0342C159.628 85.6962 160 86.594 160 87.5302V258.025C160 259.521 159.06 260.855 157.65 261.353L87.35 286.17C85.8293 286.707 84.1707 286.707 82.65 286.17L12.35 261.353C11.6621 261.11 11.0668 260.659 10.6459 260.063C10.225 259.467 9.99934 258.755 10 258.025V87.5302Z"
          fill={`url(#${id}_gradient_1)`}
        />
        <path
          d="M0.0799449 49.0803C0.0698481 48.5485 0.16585 48.0199 0.362294 47.5256C0.558737 47.0313 0.851719 46.5811 1.22411 46.2012C1.5965 45.8214 2.0409 45.5195 2.53124 45.3134C3.02158 45.1072 3.54801 45.0007 4.07995 45.0003H165.92C168.16 45.0003 169.96 46.8403 169.92 49.0803L166.05 242.275C166.036 243.079 165.779 243.86 165.314 244.515C164.848 245.17 164.195 245.669 163.44 245.946L87.78 273.971C85.9862 274.635 84.0138 274.635 82.22 273.971L6.56005 245.946C5.80542 245.669 5.15221 245.17 4.68639 244.515C4.22057 243.86 3.96382 243.079 3.94994 242.275L0.0799449 49.0803Z"
          fill={`url(#${id}_gradient_2)`}
        />
        <path
          opacity={0.1}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 45.0003H170V85.0003L85 95.0003L0 85.0003V45.0003Z"
          fill="#1F2033"
        />
        <g filter={`url(#${id}_filter_1)`}>
          <path
            opacity={0.01}
            d="M88.73 10.9633L112.67 24.7873C113.764 25.4173 114.673 26.3246 115.305 27.4178C115.937 28.511 116.27 29.7515 116.27 31.0143V58.6663C116.27 59.929 115.937 61.1695 115.305 62.2628C114.673 63.356 113.764 64.2633 112.67 64.8933L88.73 78.7163C87.637 79.3473 86.3971 79.6796 85.135 79.6796C83.8729 79.6796 82.6331 79.3473 81.54 78.7163L57.6 64.8933C56.5056 64.2633 55.5966 63.356 54.9646 62.2628C54.3326 61.1695 53.9998 59.929 54 58.6663V31.0143C53.9998 29.7515 54.3326 28.511 54.9646 27.4178C55.5966 26.3246 56.5056 25.4173 57.6 24.7873L81.54 10.9633C82.6331 10.3322 83.8729 10 85.135 10C86.3971 10 87.637 10.3322 88.73 10.9633Z"
            fill={`url(#${id}_gradient_3)`}
          />
        </g>
        <defs>
          <filter
            id={`${id}_filter_1`}
            x={27}
            y={0}
            width={116.27}
            height={123.68}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={17} />
            <feGaussianBlur stdDeviation={13.5} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_85" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2_85"
              result="shape"
            />
          </filter>
          <linearGradient
            id={`${id}_gradient_1`}
            x1={230.33}
            y1={184.197}
            x2={41.08}
            y2={44.3513}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorStart} />
            <stop offset={1} stopColor={colorStop} />
          </linearGradient>
          <linearGradient
            id={`${id}_gradient_2`}
            x1={249.71}
            y1={158.524}
            x2={35.26}
            y2={0.0182197}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorStart} />
            <stop offset={1} stopColor={colorStop} />
          </linearGradient>
          <linearGradient
            id={`${id}_gradient_3`}
            x1={147.41}
            y1={44.8403}
            x2={78.16}
            y2={-17.0387}
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset={1} stopColor="#1A1A1A" />
          </linearGradient>
        </defs>
      </svg>
    </figure>
  )
}

export default AssetItemBackground
